/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import '../css/AboutPage.css'
import Irro from '../images/Irro.webp'
import styrkelyft from '../images/styrkelyft.webp'
import mate from '../images/mate.webp'

export const AboutPage = () => {
  return (
    <div>
      <main className="pageAbout">
        <section className="AboutElla">
          <h1>Om mig</h1>
          <div className="bilder">
            <div className="Polariod">
              <img src={Irro} className="Irro" alt="Irupé Pozo Graviz" />
            </div>
            <div className="PolariodTwo">
              <img src={mate} className="mate" alt="Irupé Pozo Graviz" />
            </div>
            <div className="PolariodOne">
              <img src={styrkelyft} className="styrkelyft" alt="Irupé Pozo Graviz" />
            </div>
          </div>
          <div className="aboutParagraph">
            <p>
          Hej, jag är Irupé Pozo Graviz,
          grundaren och drivkraften bakom
          EllaTech.
            </p>
          </div>
          <div className="aboutParagraph">
            <p>
  Namnet EllaTech är en hyllning till min
bakgrund och min vision.
'Ella',det spanska ordet för 'hon',
är en nick till mina sydamerikanska
rötter och ett uttryck för
mitt engagemang för jämställdhet inom
teknikbranschen. 'Tech' representerar min passion för teknologi och allt den möjliggör.
            </p>
          </div>

          <div className="aboutParagraph">
            <p>
Som ensam grundare
är jag inte bara front-end-utvecklare och kommunikatör,
utan även en passionerad förespråkare för
tillgänglighet och inkludering.

            </p>
          </div>
        </section>

      </main>
    </div>

  )
}