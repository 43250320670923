// denna ska connectas till ServicesPage.js
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const MoreInfoBtnWebb = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/webbdesign')
  }
  return (
    <div>
      <button
        className="MoreInfoBtn"
        type="button"
        onClick={handleClick}
        onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') handleClick(); }}
        aria-label="Läs mer om våra webbdesigntjänster">
        Tjänster
      </button>
    </div>
  )
}
