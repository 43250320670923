/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import '../css/integritet.css'

export const Integrity = () => {
  return (
    <main className="integritet">
      <h1>Integritetspolicy</h1>
      <div className="integritet__info">
        <div className="integritetBlobTwo">
          <p>Välkommen till EllaTech.
Jag är engagerad i att skydda och respektera din integritet.
          </p>

          <h3>Informationssamling och Användning</h3>
          <p>Personliga uppgifter samlas när du använder
vårt kontaktformulär. Denna information kan inkludera ditt namn,
e-postadress och andra kontaktuppgifter som du frivilligt
tillhandahåller. Denna information används enbart för att
hantera dina förfrågningar och för att förbättra vår service.
          </p>
        </div>

        <h3>Cookies</h3>
        <p>Vår webbplats använder inte cookies för att samla in
information som identifierar dig personligen.
        </p>

        <h3>Säkerhet</h3>
        <p>Säkerheten för dina personuppgifter tas
på största allvar. Vi vidtar lämpliga tekniska och organisatoriska
åtgärder för att skydda dina uppgifter mot oavsiktlig eller olaglig
förstörelse, förlust, ändring, obehörig utlämnande eller åtkomst.
        </p>
        <div className="integritetBlob">
          <h3>Dina rättigheter</h3>
          <p>Enligt GDPR har du rätt att begära tillgång till,
rättelse eller radering av dina personuppgifter.
Du har även rätt att begränsa eller invända mot
behandlingen av dina uppgifter samt rätten till dataportabilitet.
          </p>
        </div>

        <h3>Kontakta oss</h3>
        <p>Om du har några frågor om integritetspolicyn,
eller om du vill utöva någon av dina rättigheter,
vänligen kontakta mig via info@ellatech.se.
        </p>

        <p>Denna policy uppdaterades senast 2023-11-30.</p>
      </div>
    </main>

  )
}