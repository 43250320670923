import React from 'react';
import { Accordion } from './AccordionComponent';

export const Questions = () => {
  const faqData = [
    {
      title: 'Varför ska jag välja EllaTech?',
      content: (
        <p>
          Jag kombinerar teknisk kompetens med passion för inkludering och tillgänglighet.
          Min bakgrund inom kommunikation och mänskliga rättigheter påverkar varje projekt positivt.
        </p>
      )
    },
    {
      title: 'Vad är headless CMS?',
      content: (
        <p>
          Ett headless CMS erbjuder en central plats för att hantera innehåll,
          som sedan kan användas på olika digitala plattformar.
          Till skillnad från traditionella CMS som WordPress,
          som kombinerar innehållshantering och webbplatsens design,
          hanterar ett headless CMS endast innehållet.
          Detta ger en större flexibilitet att publicera samma innehåll på webbplatser,
          appar, och andra enheter utan att behöva anpassa det för varje enskilt fall.
          Det är ett idealiskt val för företag som siktar
          på en omfattande närvaro över flera kanaler.
        </p>
      )
    },
    {
      title: 'Hur betalar jag?',
      content: (
        <p>
          När avtalet har signerats kommer du att erhålla en faktura motsvarande
          50% av det estimerade beloppet.
          Denna faktura bör betalas inom 10 dagar efter utfärdandet,
          dock senast dagen före påbörjandet av
          projektet. De återstående 50% av beloppet ska
          betalas i samband med projektets avslut och överlämning.
        </p>
      )
    },
    {
      title: 'Kan jag delbetala?',
      content: (
        <p>
          Hör av dig så kan vi komma överens med en betalningsplan som passar.
        </p>
      )
    },
    {
      title: 'Vilka tjänster erbjuder ni?',
      content: (
        <p>
          Webbutveckling, webbdesign, social media management,
          innehållshantering, tillgänglighet,
          inkludering, SEO och mycket mer. Kontakta mig för att diskutera dina behov och önskemål.
        </p>
      )
    },
    {
      title: 'Vilket domän ska jag välja?',
      content: (
        <div>
          <p>Valet av domän bör anpassas efter din målgrupp,
            syftet med webbplatsen och den geografiska inriktningen.
            Jag kan ge dig ytterligare råd och hjälpa dig att välja
            den bästa domänen för ditt projekt.
          </p>
        </div>
      )
    },
    {
      title: 'Villkor',
      content: (
        <div>
          <p><strong>1. Prissättning och Betalning:</strong> Kunden kommer att betala
          Leverantören enligt de priser och villkor som anges.
          </p>
          <p><strong>2. Paket och Priser:</strong> Eventuella ändringar i de avtalade
          tjänstepaketen kan påverka priset och projektets tidslinje.
          Sådana ändringar måste godkännas skriftligt av båda parter.
          </p>
          <p><strong>3. Äganderätt och Immaterialrätt:</strong>
          Kunden äger alla rättigheter till det färdiga projektet.
          Leverantören behåller rättigheterna till all kod och
          återanvändbara komponenter som utvecklats under projektets gång.
          </p>
          <p><strong>4. Kvalitetskontroll och Godkännande:</strong>
          Projektet betraktas som slutfört när alla funktioner har testats och
          godkänts enligt de överenskomna kriterierna.
          </p>
          <p><strong>5. Konfidentialitet och Sekretess:</strong> Båda parter förbinder sig att
          inte avslöja konfidentiell information till tredje part under eller efter avtalstiden.
          </p>
          <p><strong>6. Ansvarsbegränsning:</strong> Leverantörens ansvar är
          begränsat till det totala fakturerade beloppet för projektet.
          </p>
          <p><strong>7. Uppsägning och Avslutning:</strong>
          Kunden har rätt att avsluta detta avtal när som helst genom att
          betala Leverantören för alla utförda tjänster och kostnader fram till uppsägningen.
          </p>
          <p><strong>8. Garantier:</strong>
          Leverantören garanterar att alla utförda tjänster
          kommer att levereras på ett professionellt sätt och i enlighet med branschstandarder.
          </p>
          <p><strong>9. Ändringar i Omfattning eller Tidslinje:</strong>
          Eventuella ändringar i projektets omfattning eller tidslinje måste godkännas
          skriftligt av båda parter.
          Sådana ändringar kan påverka projektets kostnad och tidslinje.
          </p>
          <p><strong>10. Force Majeure:</strong>
          Leverantören är inte ansvarig för förseningar
          eller misslyckanden att utföra tjänster på grund av omständigheter utanför sin kontroll,
          såsom naturkatastrofer eller strejker.
          </p>
          <p><strong>11. Avtalsbrott:</strong>
          Definiera vad som anses vara ett avtalsbrott och vilka konsekvenser
          som kan uppstå vid sådana fall.
          </p>
          <p><strong>12. Konfliktlösning:</strong>
          Ange hur eventuella tvister mellan parterna ska lösas,
          inklusive förhandlingar, medling eller skiljeförfarande.
          </p>
          <p><strong>13. Äganderätt till Arbete:</strong>
          Leverantören behåller äganderätten till alla arbetsfiler,
          arbetsdokument och prototyper som genereras under projektets
          gång tills betalningen är fullständigt klar.
          </p>
          <p><strong>14. GDPR-efterlevnad:</strong> Leverantören åtar sig att följa alla krav
          i enlighet med GDPR (General Data Protection Regulation).
          </p>
        </div>
      )
    }
    // ... remaining faqData items
  ];

  return (
    <div className="Questions">
      <div className="blobQuestions">
        <h1>Vanliga frågor</h1>
      </div>
      <Accordion data={faqData} />
    </div>
  );
};
