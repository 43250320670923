import React from 'react';
import { Navigate } from './Nav';
import logo from '../images/logga.webp';
import '../css/header.css';

export const Header = () => {
  return (
    <header className="mainHeader">
      <div className="header">
        <div className="logo">
          <a href="/" aria-label="EllaTech Home">
            <img src={logo} alt="logo där det står ellatech och phonetsikskrift ɛʃ.a tɛk" />
          </a>
        </div>
        <nav className="Navigate" aria-label="Main navigation">
          <Navigate />
        </nav>
      </div>
    </header>
  )
}
