/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import '../css/sectionservices.css';
import { MoreInfoBtnWebb } from './moreInfoBtn';
import { ContactButton } from './ContactButton';

export const SectionServices = () => {
  return (
    <main className="mainService" aria-labelledby="services-heading">
      <h1 id="services-heading">Tjänster</h1>
      <section className="services">
        <article className="service">
          <h2>Digital närvaro</h2>
          <p>Webbsida med responsiv webbdesign.</p>
          <p>Paketet inkluderar bland annat: </p>
          <ul>
            <li>initial konsultation</li>
            <li>CMS-utbildning för enkel hantering</li>
            <li>grundläggande SEO för förbättrad synlighet</li>
            <li>hjälp att lägga upp hemsidan på nätet (exkl. avgifter för domän och ev webbhotell)</li>
          </ul>
          <div className="btnServices">
            <MoreInfoBtnWebb />
            <ContactButton />
          </div>
        </article>

        <article className="service">
          <h2>SoMe-start</h2>
          <p>
          Hjälp med att komma igång med dina sociala medier.
          </p>
          <p>Paketet inkluderar bland annat: </p>
          <ul>
            <li>tre månades samarbete</li>
            <li>förstudie</li>
            <li>strategi för sociala medier</li>
            <li>innehållsplanering</li>
            <li>innehållsproduktion</li>
          </ul>
          <div className="btnServices">
            <MoreInfoBtnWebb />
            <ContactButton />
          </div>
        </article>
        <article className="service">
          <h2>Hjälp jag är med företag!</h2>
          <p> Webbsida och komma igång med sociala medier.</p>
          <p>Paketet inkluderar bland annat: </p>
          <ul>
            <li>Tjänsten: Digital närvaro</li>
            <li>förstudie
            </li>
            <li>innehållsplanering
            </li>
            <li>innehållproduktion
            </li>
          </ul>
          <div className="btnServices">
            <MoreInfoBtnWebb />
            <ContactButton />
          </div>
        </article>
        <article className="service">
          <h2>Skräddarsy ditt egna paket</h2>
          <p> Oavsett om du behöver hjälp med kortare uppdrag eller
            längre projekt inom sociala medier eller webbutveckling,
  är jag här för att skapa ett skräddarsytt paket som passar dina behov.
  Kontakta mig så skapar vi tillsammans det perfekta paketet för ditt projekt!
          </p>
          <div className="btnServices">
            <MoreInfoBtnWebb />
            <ContactButton />
          </div>
        </article>
      </section>
    </main>
  );
};
