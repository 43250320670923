import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App'; // Make sure App is the default export
import './reset.css';
import './index.css';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
}
