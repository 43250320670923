import React from 'react'
import Lottie from 'lottie-react';
import comingSoon from '../images/comingSoon.json';
/* import { SectionPortfolio } from '../components/sectionportfolio' */
import '../css/portfolio.css'

export const PortfolioPage = () => {
  return (
    <div className="portfolioMain">
      {/* <SectionPortfolio /> */}
      <h1>Kodning pågår</h1>
      <Lottie animationData={comingSoon} style={{ width: 250, height: 250 }} className="lottie" />
    </div>
  )
}