/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { ContactButton } from '../components/ContactButton';
import { Questions } from '../components/Questions';
import webb from '../images/megafonen.png';
import '../css/WebbDesign.css';

export const Webbdesign = () => {
  return (
    <main className="MainService">
      <div className="ServiceHeader">
        <div className="GridContent">
          <section>
            <img src={webb} alt="Tjej som håller i en megafon och ovan för henne tre knytna nävar som uttrycker kamp" />
          </section>
          <div className="grodd">
            <h1>Kommunikation och webbdesign för ett inkluderande samhälle</h1>
            <p>
              Med ett normkritiskt och inkluderande förhållningssätt
              strävar jag efter att göra skillnad på nätet.
              Som frilansande webbutvecklare och socia media manager
              är jag här för att hjälpa dig bygga en stark digital närvaro.
            </p>
          </div>
        </div>
        <div className="blobi">
          <p>Välj mellan olika paket eller kontakta EllaTech för en skräddarsydd offert.</p>
        </div>
        <div className="blob">
          <p>Alla paket innehåller SEO, säkerhet, tillgänglighet och inkluderande språk.</p>
          <p>Webbdesignen skapas från grunden med React.js och implementeras med headless
            CMS för smidig redigering och hantering. Hosting sker via Netlify och  jag hjälper till med domän-setup.
          </p>
          <p>Priserna är exklusive moms och pris för ev domän tillkommer.</p>
        </div>
        <div className="blobo">
          <p>Är du intresserad eller har frågor?  Boka en gratis konsultation.</p>
        </div>
        <div className="projectBtnOne">
          <ContactButton />
        </div>
      </div>

      <div className="serviceList">
        <li className="package">
          <h2>🌈 Digital närvaro</h2>
          <ul>
            <p>Idealiskt för nya företag som vill starta sin online närvaro.</p>
            <p>Webbsida med modern och responsiv webbdesign. Paketet inkluderar: </p>
            <li>en sida med fyra Sektioner</li>
            <li>inkluderar initial konsultation för att definiera mål och behov</li>
            <li>CMS-utbildning för enkel hantering</li>
            <li>hjälp med content och inkluderande språk</li>
            <li>grundläggande SEO för förbättrad synlighet</li>
            <li>grundläggande säkerhetsfunktioner och GDPR</li>
            <li>konfiguration på valt webbhotell (exkl. avgifter).</li>
            <p className="pris"> Pris: 20000 kr  </p>
            <div className="projectBtnOne">
              <ContactButton />
            </div>
          </ul>
        </li>

        <li className="package">
          <h2>🌈 SoMe-start</h2>
          <p>Behöver du hjälp med att komma igång med dina sociala medier?
            Ingen aning hur? Don't dispair, EllaTech är här!
          </p>
          <p>Under tre månader får du hjälp med att komma igång med dina sociala medier.</p>
          <ul>
            <li>Förstudie: Vi ser över vilka sociala medier som
              skulle passa dig bäst. Har du redan sociala medier?
              Då gör vi en undersökning av dina nuvarande sociala medier.
            </li>
            <li>Innehållsplanering: Vi gör en plan och visar dig
              hur du kan strukturera upp dina inlägg för sociala medier.
            </li>
            <li>Innehållproduktion: Vi producerar innehåll för två inlägg
              i veckan för dina sociala medier.
            </li>
          </ul>
          <p className="pris"> Pris: 30000kr månaden</p>
          <div className="projectBtnOne">
            <ContactButton />
          </div>
        </li>

        <li className="package">
          <h2>🌈 Webbutveckling och SoMe</h2>
          <p>Du får du hjälp med att få upp en hemsida och komma igång med dina sociala medier.</p>
          <ul>
            <p>I paketet ingår:</p>
            <li>Tjänsten: Digital Närvaro</li>
            <li>Förstudie: Vi ser över vilka sociala medier som
              skulle passa dig bäst. Har du redan sociala medier?
              Då gör vi en undersökning av dina nuvarande sociala medier.
            </li>
            <li>Innehållsplanering: Vi gör en plan och visar dig
              hur du kan strukturera upp dina inlägg för sociala medier.
            </li>
            <li>Innehållproduktion: Vi producerar innehåll för tre inlägg
              i veckan för dina sociala medier.
            </li>
            <p className="pris"> Pris: 50000 kr</p>
            <div className="projectBtnOne">
              <ContactButton />
            </div>
          </ul>
        </li>

        <div className="package">
          <h2>🌈 Skräddarsy ditt egna paket</h2>
          <p> Oavsett om du behöver hjälp med kortare uppdrag eller
            längre projekt inom sociala medier eller webbutveckling,
  är jag här för att skapa ett skräddarsytt paket som passar dina behov.
  Kontakta mig så skapar vi tillsammans det perfekta paketet för ditt projekt!
          </p>

          <div className="projectBtnOne">
            <ContactButton />
          </div>
        </div>
      </div>

      <Questions />
    </main>
  );
};