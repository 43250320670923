import React, { useState } from 'react';
import '../css/accordion.css'; // Se till att du har en motsvarande CSS-fil

export const AccordionItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const itemClass = `accordion-item ${isOpen ? 'open' : ''}`;
  const ariaExpanded = isOpen ? 'true' : 'false';

  return (
    <div className={itemClass}>
      <button
        type="button"
        className="accordion-title"
        onClick={toggle}
        aria-expanded={ariaExpanded}>
        {title}
        <span className="accordion-icon">{isOpen ? '-' : '+'}</span>
      </button>
      <div
        className="accordion-content"
        style={{ display: isOpen ? 'block' : 'none' }}
        aria-hidden={!isOpen}>
        {children}
      </div>
    </div>
  );
};

export const Accordion = ({ data }) => {
  return (
    <div className="accordion">
      {data.map((item) => (
        <AccordionItem key={item.title} title={item.title}>
          {item.content}
        </AccordionItem>
      ))}
    </div>

  );
};

