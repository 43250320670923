import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { AboutPage } from './pages/AboutPage';
import { Webbdesign } from './pages/Webbdesign';
import { SectionAbout } from './components/sectionAbout';
import { SectionServices } from './components/sectionservices';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { ModalProvider } from './components/ModalContext';
import { ContactModal } from './components/modal';
import { PortfolioPage } from './pages/portfolio';
import { Integrity } from './pages/integritetspolicy';
import NotFoundPage from './components/pagenotfound';
// import CookieBanner from './components/CookieBanner';
import './css/buttons.css';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};
export const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className="app-container" role="main"> {/* Lägg till roll "main" för huvudinnehåll */}
        <ModalProvider>
          <Header role="banner" /> {/* Lägg till roll "banner" för sidhuvud */}
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <SectionAbout />
                  <SectionServices />

                </>
              } />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/webbdesign" element={<Webbdesign />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
            <Route path="/integritetspolicy" element={<Integrity />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <ContactModal />
          <Footer role="contentinfo" />
        </ModalProvider>
      </div>
    </Router>
  )
}
