// i filen NotFoundPage.js inom mappen pages
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import Lottie from 'lottie-react';
import notFound from '../images/404.json';
import home from '../images/Home.svg';
import '../css/notfound.css';

const NotFoundPage = () => {
  const navigate = useNavigate();

  // Function to handle navigation to the home page
  const navigateHome = () => {
    navigate('/');
  };

  return (
    <main className="pagenotfound">
      {/* Use the button element for navigation */}
      <button type="button" onClick={navigateHome} className="castle-button">
        <img src={home} alt="home" className="home-icon" />
        <span>Hem</span>
      </button>

      <div className="notfound">
        <Lottie animationData={notFound} style={{ width: 300, height: 300 }} className="lottie" />
        <h1>
        Sidan hittades inte
        </h1>
        <p>Det verkar som att sidan du försöker nå inte existerar.</p>
      </div>
    </main>
  );
};

export default NotFoundPage;

