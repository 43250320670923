import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../css/sectionAbout.css'
import { ContactButton } from './ContactButton'
import aboutSection from '../images/revolution.png'

export const SectionAbout = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/about')
  }

  return (
    <section className="main" aria-labelledby="about-heading">
      <h1 id="about-heading">EllaTech</h1>
      <div className="grid">
        <img className="aboutImage" src={aboutSection} alt="girl screaming revolution" />
        <div>
          <p className="about">
            <strong className="bold">Frilansande kommunikatör och webbutvecklare som strävar efter att göra skillnad på nätet.</strong>
            <div className="webbyrå">
              <p>Som social media manager och webbutvecklare har
            jag skapat en plattform där tillgänglighet,
            design och inkluderande språk är i centrum.
            Genom skräddarsydd design och tillgänglighet ser
            jag till att din webbplats och sociala medier är
            visuellt tilltalande samt välkomnande för alla besökare.
              </p>

            </div>
          </p>
        </div>
      </div>
      <div className="buttons">
        <button type="button" className="btnAbout" onClick={handleClick}> Om Mig </button>
        <ContactButton />
      </div>
    </section>
  )
}
